<template>
  <v-list-item class="px-0">
    <v-list-item-avatar>
      <v-icon v-text="icon" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-text="title" />
    </v-list-item-content>
    <v-list-item-action>
      <v-radio class="" v-bind="$attrs" v-on="$listeners" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'FormRadio',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  }
};
</script>

<style></style>
